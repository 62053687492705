<template>
  <footer class="inside-footer">
    <div class="name">一般社団法人地域医療介護連携推進機構</div>
    <address>〒010-1617&nbsp;秋田県秋田市新屋松美ガ丘東町７−４&nbsp;２階&nbsp;TEL:080-4209-7029</address>
    <nav>
      <ul class="footer-menu">
        <li><router-link to="/">HOME</router-link></li>
        <li class="splitter"></li>
        <li><router-link to="/about">COCOについて</router-link></li>
        <li class="splitter"></li>
        <li><router-link to="/privacy">個人情報保護方針</router-link></li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'CocoFooter'
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';


footer.inside-footer {
  // margin-top: 200px;
  div.name {
    margin: 0 auto;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
  }
  address {
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
  }
  nav {
    padding-top: 52px;
    padding-bottom: 62px;
    display: flex;
    justify-content: center;
    ul.footer-menu {
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-block-start: 0;
      margin-inline-start: 0;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 6px;
        width: 100px;
        height: 26px;
        font-size: 11px;
        text-align: center;
        &.splitter {
          width: 1px;
          height: 26px;
          margin: 0;
          border-left: 1px solid $border-accent-color;
        }
        a {
          color: $content-base-color;
          text-decoration: none;
        }
      }
    }
  }
  @include mediaQuery('phone') {
    div.name {
      padding-top: 20px;
      font-size: 16px;
    }
    address {
      margin-top: 10px;
      font-size: 13px;
    }
    nav {
      padding: 22px 0;
    }
  }
}
</style>
